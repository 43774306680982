import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { Link } from "gatsby"

// Components
import Layout from "../../components/Layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import Product from "../../components/Product"
import CategoryFooter from "../../components/pages/CategoryFooter"

//Content
import content from "../../pages/product-cats/wall-mounted-air-conditioning.yaml"
import categories from "../../pages/static-pages/index.yaml"

class Index extends React.Component {
  render() {
    const breadcrumbs = [
      {
        label: content.title,
        path: "/wall-mounted-air-conditioning/",
        active: true,
      },
    ]

    const items = []
    const products = this.props.data.allProductsYaml.edges
    products.forEach((product) => {
      items.push(<Product data={product} key={product.node.fields.slug} />)
    })

    return (
      <Layout>
        <Helmet defer={false}>
          <title>{content.seo_title}</title>
          <meta name="description" content={content.seo_description} />
          <meta property="og:image" content={content.seo_image} />
        </Helmet>

        <Breadcrumbs items={breadcrumbs} absolute={true} contrast={true} />

        {/* Banner */}
        <div
          className="feature-banner uk-section uk-background-cover uk-light"
          data-src={content.feature.image.url}
          uk-img=""
        >
          <div className="uk-container uk-margin-large-top">
            <div className="uk-width-4-5 uk-width-3-5@s uk-width-1-3@m">
              <h1
                className="uk-margin-small-top uk-text-global-family uk-h2 uk-text-bold uk-invisible"
                dangerouslySetInnerHTML={{ __html: content.feature.title }}
              ></h1>
            </div>
          </div>
        </div>

        {/* Feature */}
        <div className="feature-banner uk-section uk-section-default uk-padding-remove-top">
          <div className="uk-container uk-margin-large-top">
            <div className="uk-width-2-3@m uk-margin-auto uk-text-center">
              <h1 className="uk-h2 uk-margin-medium uk-text-light">
                {content.title}
              </h1>
              <p className="uk-text-medium uk-margin-medium uk-margin-remove-top uk-text-light">
                {content.description}
              </p>
            </div>
          </div>
        </div>

        {/* Products list */}
        <div className="feature-banner uk-section uk-section-secondary uk-padding-remove-top">
          <div className="uk-container">
            <div className="uk-width@l uk-margin-large-top uk-text-center">
              <div
                className="uk-grid uk-grid-small uk-flex-center uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m"
                uk-grid=""
              >
                {items}
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section">
          <div className="uk-container">
            <CategoryFooter
              data={categories.panels}
              title={categories.panelsTitle}
              url={breadcrumbs}
            />
          </div>
        </div>

        <div className="uk-section uk-section-small">
          <div className="uk-container">
            <div className="uk-width-3-4 uk-width-2-3@s uk-width-1-2@m">
              <small className="uk-text-meta">{content.seo_description} </small>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index

export const categoryQuery = graphql`
  query wallMountedQuery {
    allProductsYaml(
      limit: 2
      filter: { categories: { eq: "wallMounted" } }
      sort: { fields: sort, order: ASC }
    ) {
      edges {
        node {
          id
          title
          description_short
          variants {
            price
          }
          featured_image
          fields {
            slug
          }
        }
      }
    }
  }
`
